import { Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import { AddToCart } from "../../../../Store/action/listingActions";
import { checkAndLogExceedLimit, countIdsWithQuantity, ga4Events, popUp, ProductImage, stringToUrl } from "../../../../utils";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";
import { handRequestForPrice } from "../../../content/element/card/card-listing-grid";
import NotLoggedInModal from "../../../content/element/modal/not-logged-modal";


export const PurchaseListingCard = ({purchaseValue }) => {
    const { allCategories } = useSelector((state) => state.category)
    const { quotationItems } = useSelector((state) => state.quotation)
    const { collection, page, itemsInCart } = useSelector((state) => state.list)
    const history = useHistory()
    const [isLogin, setIsLogin] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const dispatch = useDispatch()
  
    const list = useSelector((state) => state.list);

    const {
      imageArray,
      taImages,
      tradeSoftImages,
      partName,
      partSKU,
      partBrand,
      priceExclVat,
      _id,
      badge,
      setPathname,
      type,
      quantity,
      category,
      variant,
    } = purchaseValue.listing;

    let url =
    imageArray?.[0] ||
    taImages?.map(
        (obj) => obj['imageURL400'] || obj['imageURL800']
    )?.[0] || tradeSoftImages?.[0] ||
    'assets/img/byparts.jpg.jpg'
    const link = stringToUrl(partName) + "--" + stringToUrl(partSKU) + `/${_id}`;
    const currentUser = JSON.parse(localStorage.getItem('login'));
    const result = countIdsWithQuantity(itemsInCart);
    const handleAddToCart = (id, quantity) => {
      const itemInCart = result.find(item => item.id === id);
      const isAvailable = checkAndLogExceedLimit(id, result, quantity);
  
      if (currentUser) {
        const findCategory =
          allCategories?.length !== 0 &&
          allCategories?.find((c) => c?._id === category)
  
        let data = {
          action: `${page === 'collection' ? 'collection_plp' : 'plp'
            }_add_to_cart`,
          category: 'Cart',
          product_id: _id,
          product_name: partName,
          product_category: findCategory?.name,
          product_brand: partBrand,
          product_variant: variant,
        }
  
        if (collection?._id) {
          data = {
            ...data,
            collection_id: collection?._id,
            collection_name: collection?.title,
          }
        }
        if ((isAvailable)) {
          popUp("Your cart items quantity exceeded stock quantity.")
        } else {
          dispatch(AddToCart(_id, data));
        }
        setPathname && setPathname(history.location.pathname)
        localStorage.setItem(
          'filterPath',
          JSON.stringify(history.location.pathname)
        )
      } else {
        popUp(
          'You need to register as a buyer to be able to make an order on BuyParts24'
        )
        setIsLogin(true)
      }
    }
  
    const [isCopied, setIsCopied] = useState(false)
    useEffect(() => {
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    }, [isCopied])
    function handleNewQuotation() {
      if (currentUser) {
        handRequestForPrice(
          quotationItems,
          { _id, partName, priceExclVat, qty: 1, url },
          dispatch
        )
      } else {
        popUp('You need to register as a buyer to be able to request price')
      }
    }
    return (
      // !cardLoading && (
        <div className='atbd_single_listing '>
          <NotLoggedInModal
            showModal={isLogin}
            onClose={setIsLogin}
            msg={errMsg}
          />
          <article className='atbd_single_listing_wrapper'>
            <figure className='atbd_listing_thumbnail_area'>
              <NavLink
                to={`/spare-part-details/${link}`}
                >
                <div
                  className='atbd_listing_image'
                  style={{ display: 'flex', position: 'relative' }}>
                  <ProductImage avatar={url} alt='Listing' />
                </div>
              </NavLink>
  
              <div className='atbd_thumbnail_overlay_content'>
                <ul className='atbd_upper_badge'>
                  <li>
                    <span
                      className={
                        'text-capitalize atbd_badge atbd_badge_' +
                        badge?.replace(' ', '_')
                      }>
                      {badge}
                    </span>
                  </li>
                </ul>
              </div>
            </figure>
            <div className='atbd_listing_info'>
              <h4 className='atbd_listing_title'>
                <NavLink
                  to={`/spare-part-details/${link}`}
                  >
                  {partName}
                </NavLink>
              </h4>
              <div className='rating-group'>
                <div className='d-flex '>
                  <button className='btn part-brand'>
                    {/* {partBrand} */}
                    {partBrand && <NavLink
                      className={'cursur-pointer'}
                      style={{
                        color: 'black',
                      }}
                      to={`/all-listings?brand=${partBrand
                        .toLocaleLowerCase()
                        .replace(' ', '-')}`}
                     >
                      <a>{partBrand?.toUpperCase()}</a>
                    </NavLink>}
                  </button>
                </div>
                <span className='review-value'>{type}</span>
              </div>
              {/* {!istecAll ? ( */}
                <>
                  <div className="d-flex justify-content-between align-items-center card__sku-container">
                      <button
                        className='btn part-sku'
                        title={partSKU}
                        onClick={() => {
                          setIsCopied(true)
                          navigator.clipboard.writeText(partSKU) 
                        }}>
                        SKU{' '}
                        <span className='part' style={{ marginLeft: '2px' }}>
                          {partSKU}
                        </span>{' '}
                        <span className='ml-2'>|</span>
                        <Tooltip
                          className='ml-1 cursur-pointer'
                          title='SKU number copied to clipboard'
                          placement='top'
                          open={isCopied}
                          arrow
                          style={{minWidth: "24px"}}
                          disableHoverListener>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='18'
                            viewBox='0 0 24 24'
                            width='24'>
                            <path d='M0 0h24v24H0z' fill='none' />
                            <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' />
                          </svg>
                        </Tooltip>
                      </button>
                    {/* <span className="alias-name">{aliasName}</span> */}
                  </div>
                  <div className='pricing-group'>
                    {quantity && quantity > 0 ? (
                      currentUser ? (
                        <p className='symbol'>
                          <ShowDynamicCurrency />
                          <span className='price'>
                            <ShowDynamicPrice price={priceExclVat} hideExc />
                          </span>
                        </p>
                      ) : (
                        <p className='symbol' style={{ padding: '11px' }}>
                          <span className='price'></span>
                        </p>
                      )
                    ) : (
                      <p></p>
                    )}
                    {quantity && quantity > 0 ? (
                      <button
                        className='btn cart-btn'
                        disabled={currentUser?.role == 'SELLER' || list.isAddToCartLoading}
                        onClick={() => {
                          handleAddToCart(_id, quantity)
                        }}>
                        Add To Cart
                      </button>
                    ) : (
                      <div
                        style={{
                          marginTop: !currentUser && '10px',
                        }}>
                        <button
                          onClick={handleNewQuotation}
                          className='btn cart-btn'
                          style={{
                            marginBottom: !currentUser ? '10px' : '20px',
                            backgroundColor: '#1d71b8',
                            color: 'white',
                          }}>
                          {'Request For Price'}
                        </button>
                      </div>
                    )}
                  </div>
                  <span
                    style={{
                      fontSize: 10,
                      fontWeight: 600,
                      margin: 0,
                      padding: 0,
                    }}
                    className='text-muted'>
                    {quantity > 0 && currentUser ? 'Exc.VAT' : ' '}
                  </span>
                </>
              {/* ) 
              : (
                <>
                  <p style={{ fontSize: 12, fontWeight: 600 }}>
                    SKU{' '}
                    <span
                      style={{
                        color: 'black',
                        fontSize: 12,
                        fontWeight: 600,
                      }}>
                      {partSKU}
                    </span>
                  </p>
                  <div
                    className='price-group'
                    style={{ justifyContent: 'flex-end' }}>
                    <button
                      className={
                        !isForRequest ? 'btn cart-btn' : 'btn cart-btn d-none'
                      }
                      disabled={login?.role == 'SELLER'}
                      onClick={() => {
                        if (login === null) {
                          return history.push('/register')
                        }
                        onreceivestate(
                          pic,
                          imageArray,
                          partName,
                          partSKU,
                          price,
                          _id,
                          date,
                          url,
                          title,
                          badge,
                          link,
                          api,
                          description
                        )
                      }}>
                      On Request
                    </button>
                  </div>
                </>
              )} */}
            </div>
          </article>
        </div>
      // )
    )
  }