const initState = {
    selectedCategory: "all",
    selectedSubCategory: "",
    categories: [],
    subCategories: [],
    filteredSubCategories: [],
    brands: [],
    lubsBrand:[],
    filteredBrands: [],
    isBrandLoading: false,

    listingBrands: [],
    totalListingBrands: 0,
    isListingBrandLoading: false,

    batteriesBrands: [],
    tyreBrands: [],

    batteriesBrandsData: [],
    tyreBrandsData: [],
  //  lubricantsBrandsData:[],
    filteredCatalogMakeBrands: [],
    filteredCatalogBatteriesBrands: [],
    filteredCatalogTyreBrands: [],
  //  filteredCatalogLubricantBrands:[]
}

const BrandReducer = (state = initState, action) => {
    
    switch (action.type) {
        case 'GET_ALL_BRAND_BEGIN':
            return {
                ...state,
                isBrandLoading: true,
            }
        case 'GET_ALL_BRAND_SUCCESS':
            return {
                ...state,
                categories: action.data?.categories,
                subCategories: action.data?.subCategories,
                brands: action.data?.brands,
                filteredBrands: action.data?.brands,
                isBrandLoading: false,
                //filteredCatalogMakeBrands: action.data?.brands.filter(obj=>obj.category.name === 'Genuine')
                /**
                 * HARIOM: TEMPORARY CHANGES BELOW TO BLOCK SOME OF THE MAKES
                 */
                filteredCatalogMakeBrands: action.data?.brands.filter(obj => (obj.category.name === 'Genuine' && obj.name !== 'MOPAR' && obj.name !== 'Motorcraft'))
            }
        case 'GET_ALL_BRAND_ERROR':
            return {
                ...state,
                isBrandLoading: false,
            }
        case 'FILTER_CATEGORY':
            return {
                ...state,
                selectedCategory: action?.data?.selectedCategory,
                filteredSubCategories: action?.data?.filteredSubCategories,
                selectedSubCategory: action.data?.selectedSubCategory,
                filteredBrands: action?.data?.filteredBrands
            }
        case 'FILTER_SUB_CATEGORY':
            return {
                ...state,
                selectedSubCategory: action?.data?.selectedSubCategory,
                filteredBrands: action?.data?.filteredBrands,
            }
        case 'GET_ALL_LISTING_BRAND_BEGIN':
            return {
                ...state,
                isListingBrandLoading: true,
            }
        case 'GET_ALL_LISTING_BRAND_SUCCESS':
            return {
                ...state,
                listingBrands: action?.data?.listingBrands || [],
                totalListingBrands: action?.data?.total || 0,
                isListingBrandLoading: false,
            }
        case 'GET_BATTERY_BRAND_SUCCESS':
            return {
                ...state,
                batteriesBrands: action?.data || [],
            }
        case 'GET_TIRE_BRAND_SUCCESS':
            return {
                ...state,
                tyreBrands: action?.data || [],
            }
            // case 'GET_LUBRICANTS_BRAND_SUCCESS':
            //     return {
            //         ...state,
            //         lubricantsBrands: action?.data || [],
            //     }
        case 'GET_BATTERY_BRAND_DATA_SUCCESS':
            return {
                ...state,
                batteriesBrandsData: action?.data || [],
                filteredCatalogBatteriesBrands: action?.data?.listings.map(obj=>{
                    return { 
                        brand_img: obj.imageArray[0],
                        name: obj.partBrand
                    }
                }),
                isListingBrandLoading: false,
            }
        case 'GET_TIRE_BRAND_DATA_SUCCESS':
            return {
                ...state,
                tyreBrandsData: action?.data || [],
                filteredCatalogTyreBrands: action?.data?.listings.map(obj=>{
                    return { 
                        brand_img: obj.imageArray[0],
                        name: obj.partBrand
                    }
                }),
                isListingBrandLoading: false,
            }
            // case 'GET_LUBRICANTS_BRAND_DATA_SUCCESS':
            //     return {
            //         ...state,
            //         lubricantsBrandsData: action?.data || [],
            //         filteredCatalogLubricantBrands: action?.data?.listings.map(obj=>{
            //           if(obj.hide === false){
            //             return { 
            //                 brand_img: obj.imageArray[0],
            //                 name: obj.partBrand
            //             }

            //           }
            //         }),
            //         isListingBrandLoading: false,
            //     }
        case 'GET_ALL_LISTING_BRAND_ERROR':
            return {
                ...state,
                isListingBrandLoading: false,
            }
  case 'GET_ALL_LUBS_BEGIN':
                return {
                    ...state,
                    isBrandLoading: true,
                }
            case 'GET_ALL_LUBS_SUCCESS':
                return {
                    ...state,
                  // categories: action.data?.categories,
                   // subCategories: action.data?.subCategories,
                   lubsbrand: action?.data?.lubsbrand
                //     filteredBrands: action.data?.brands,
                //     isBrandLoading: false,
                //    // filteredCatalogMakeBrands: action.data?.brands
                //        filteredCatalogMakeBrands: action.data?.brands.filter(obj=>obj.category.name === 'Genuine')
                }
                case 'GET_ALL_LUBS_ERROR':
                    return {
                        ...state,
                        isBrandLoading: false,
                    }
            
        default:
            return state;
    }
};
export default BrandReducer;
