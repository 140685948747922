import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import PurchaseFilter from './PurchaseFilter';
import { PurchaseListingCard } from './PurchaseListingCard';


const PurchaseListing = () => {
  const [purchaseData, setPurchaseData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const currentUser = JSON.parse(localStorage.getItem("login"));

  const list = useSelector((state) => state.list)
  const getPurchase = async (page) => {
    let response = await axios.get(
      `api/order/buy-again?buyer=${currentUser?._id}&status=delivered&page=${page}&size=${dataPerPage}`
    );
    setPurchaseData(response?.data[0]?.data);
    response?.data && response?.data?.length && setTotalCount(response?.data[0]?.paginationData?.total)
  }

  useEffect(() => {
    if (currentUser) {
      getPurchase(page);
    }
  }, []);

  const handlePagination = (e, page) => {
    setPage(page);
    getPurchase(page);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

  }

  return (
    <section className='all-listing-wrapper'>
      <div className='container-fluid'>
        <div className='row'>
          {/* <div className='col-lg-2 order-lg-0 order-0'>
            <PurchaseFilter list={list}/>
          </div> */}
          <div className={'col-lg-12 order-lg-1 order-1'} style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
            <div
              className='row listing-5cards-wrapper'
              style={{ display: 'block' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                  purchaseData && purchaseData.length &&
                  purchaseData.map((purchase, index) => {
                    return (
                      <div
                        className={'listing-card-grid'}
                        key={index}
                        style={{ minWidth: 160 }}>
                        <PurchaseListingCard
                          purchaseValue={purchase}
                        />
                      </div>
                    )
                  })
                }
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='mt-2'>
                      <Pagination
                        count={Math.ceil(totalCount / dataPerPage)}
                        variant='outlined'
                        shape='rounded'
                        page={page}
                        onChange={handlePagination}
                        style={{ marginTop: '10px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PurchaseListing;