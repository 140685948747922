import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from "react-multi-carousel";

import { getListingDeliveryTime, stringToUrl } from '../../../../utils';
import PurchaseCard from '../card/PurchaseCard';

const PurchaseSlider = ({ skus }) => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    const { currency, conversionRate } = useSelector((state) => {
        return {
            currency: state.currency.currency,
            conversionRate: state.currency.conversionRate,
        };
    });

    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={1000}
            centerMode={false}
            containerClass="container-with-dots"
            draggable
            className="mt-4"
            focusOnSelect={false}
            infinite={false}
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                superLargeDesktop: {
                    breakpoint: {
                        max: 4000,
                        min: 1200
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                },
                largeDesktop: {
                    breakpoint: {
                        max: 1200,
                        min: 1112
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                },
                desktop: {
                    breakpoint: {
                        max: 1112,
                        min: 990
                    },
                    items: 3,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 990,
                        min: 500
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                },
                mobile: {
                    breakpoint: {
                        max: 500,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {skus.map((value, index) => {
                const { partName, price, _id, user, priceExclVat, quantity, imageArray, partSKU,
                    partBrand, business_model, type, taImages, tradeSoftImages } = value.listing;

                const title = `${partName}`;
                const badge = "";
                const link =
                    stringToUrl(partName) + "--" + stringToUrl(partSKU) + `/${_id}`;
                let url =
                    imageArray?.[0] ||
                    taImages?.map(
                        (obj) => obj['imageURL400'] || obj['imageURL800']
                    )?.[0] || tradeSoftImages?.[0] ||
                    'assets/img/byparts.jpg.jpg'
                const business_model_param = business_model;
                const quantity_param = quantity;
                const deliveryTime = getListingDeliveryTime({
                    business_model_param,
                    quantity_param,
                    bmDTs: user?.bmDTs
                });

                const aliasName = user?.aliasName

                let data = {
                    ...value,
                    title,
                    badge,
                    link,
                    url,
                    price,
                    conversionRate: conversionRate,
                    currency: currency,
                    role: currentUser?.role,
                    partBrand: partBrand,
                    eventAction: "product_slider",
                    deliveryTime,
                    aliasName,
                    partSKU: partSKU,
                    type: type,
                    priceExclVat,
                    quantity,
                    _id: _id
                };

                return (
                    <div className='slider-listing' style={{ margin: "4px 4px" }} key={index}>
                        <PurchaseCard {...data} />
                    </div>
                );
            })}
        </Carousel>
    )
}
export default PurchaseSlider;