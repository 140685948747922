import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux'
import axios from 'axios'
import ReactGA from 'react-ga4'

import SellerListings from './page/seller-listings'
import ListingDetails from './page/newlisting-details'
import TrackingOrder from './page/Trackingorder'
import Thankyou from './page/Thankyou/index'
import ThankyouRegister from './page/ThankyouRegister/index'
import Credits from './page/Credits/index'
import MyListing from './page/my-listing'
import MyReview from './page/my-review'
import SearchTiresBatteries from './page/TiresBatteries/index'
import Index from './page/index'
import AllListing from './page/all-listing'
import Register from './page/register/index'
import RegisterSeller from './page/register/register-seller'
import RegisterBuyer from './page/register/register-buyer'
import VerifyEmail from './page/register/VerifyEmail'
import VerifyPhone from './page/register/VerifyPhone'
import PasswordForm from './page/register/password-form'
import AddParts from './page/add-parts'
import EditParts from './page/edit-parts'
import HowItWorks from './page/how-it-works'
import AboutUs from './page/about-us'
import ContactUs from './page/contact-us'
import ImageRecog from './page/image-reco'
import News from './page/news'
import Shipping from './page/shipping'
import YourOrdersBuyer from './page/your-orders-buyer'
import YourOrderSeller from './page/your-orders-seller'
import YourAddress from './page/YourAddress'
import OrderDetails from './page/order-details'
import NewsDetails from './page/news-details'
import SellerDetails from './page/seller-details'
import SellerList from './page/SellerDetails/Index'
import { LogOut } from '../Store/action/logoutAction'
import BecomeBuyer from './page/BecomeBuyer'
import BecomeSeller from './page/BecomeSeller'
import SearchVin from './page/search-vin'
import Checkout from './page/Checkout/index'
import Chats from './page/chats'
import RequestPart from './page/request-part'
import MyPayment from './page/Payment'
import MyProfile from './page/my-profile'
import MyStore from './page/my-store'
import MyDashboard from './page/my-dashboard'
import MyOrder from './page/my-order'
import MySales from './page/my-sales'
import Settings from './page/settings'
import Quotation from './page/quotation'
import EinvoiceMobile from './page/EinvoiceMobile'
import RfqDetails from './content/element/quotation/RFQDetail'
import MyMessages from './content/element/Message'
import BuyerDeliveries from './content/element/deliveries/Buyer-Deliveries'
import SellerDeliveries from './content/element/deliveries/Seller-Deliveries'
import FAQPage from './page/FAQ'
import Help from './page/Help'
import TermsAndConditions from './page/TermsAndConditions'
import RefundPrivacy from './page/ReefundPrivacy'
import PrivacyPolicy from './page/PrivacyPolicy'
import Collections from './page/Collections'
import MyRequest from './page/MyRequest'
import NotFound from './page/NotFound'
import OnlinePayment from './page/OnlinePayment'
import ThreeDsPayment from './page/ThreeDsPayment'
import Disscussion from './discussion/Disscussion'
import chats from './discussion/chats'
import SellerRegister from './page/register/SellerRegister'
import Maintainance from './page/Maintainance'
import Footer from './layout/footer'

import { WebSocketContext } from '../WebSocket'
import { ignoredUrls } from '../utils'
import JSONInfo from './page/JSONInfo'
import { baseUrl } from '../constants'
import '../i18n'

import { getNotificationInital } from '../Store/action/notificationAction'
import { SetDefaultAddress, UpdateUserInfo } from '../Store/action/loginActions'
import { getBannerList, setUserCart } from '../Store/action/listingActions'
import { getAllCategories } from '../Store/action/categoryActions'
import { getAllBrands, getCatalogBrands, getAllLubricants } from '../Store/action/brandActions'
import { getHomepageSection } from '../Store/action/homepageSectionActions'
import { getUserDetail } from '../Store/action/userAction'
import { getAllStates } from '../Store/action/dataActions'
import { setLoading, setMaintainceMode, getDeliveryTime } from '../Store/action/settingsAction'
import { store } from '..'

import 'react-multi-carousel/lib/styles.css'
import MobileFooter from './layout/mobile-footer';
import MobileMenu from './layout/mobile-menu/MobileMenu'
import HeaderWrapper from './layout/header/HeaderWrapper'
import MobileSearch from './layout/mobile-search/MobileSearch'
import ComingSoon from './page/ComingSoon'
import PurchaseListing from './content/element/card/PurchaseListing'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)

axios.defaults.baseURL = baseUrl

// Added a request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token')

  if (token && !config?.url.includes(ignoredUrls)) {
    config.headers.Authorization = token
  }

  return config
})

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    store.dispatch(setLoading(false))
    return response
  },
  async (error) => {
    const originalRequest = error.config
    store.dispatch(setLoading(false))
    // If the token has expired and we need to refresh it
    if (error.response?.status === 403 || error.response?.status === 401) {
      localStorage.removeItem('login')
      localStorage.removeItem('access_token')
      window.location.href = '/'
    }

    if (error?.message === "Network Error") {
      store.dispatch(setMaintainceMode(true))
    }

    return Promise.reject(error)
  }
)

const BuyerPrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem('login'))
        if (!currentUser || currentUser.role !== 'BUYER') {
          return (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            />
          )
        }
        return <Component {...props} />
      }}
    />
  )
}

const SellerPrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem('login'))
        if (!currentUser || currentUser.role !== 'SELLER') {
          return (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            />
          )
        }
        return <Component {...props} />
      }}
    />
  )
}

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem('login'))
        if (!currentUser) {
          return (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            />
          )
        }
        return <Component {...props} currentUser={currentUser} />
      }}
    />
  )
}

const RestrictedRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem('login'))

        if (currentUser) {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
        return <Component {...props} currentUser={currentUser} />
      }}
    />
  )
}

class App extends Component {
  static contextType = WebSocketContext

  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
      showMobileSearch: false
    }
  }

  componentDidMount() {
    this.props.GetBannerList()
    this.props.getAllCategories()
    this.props.getAllBrands()
    this.props.getAllStates()
    this.props.getAllBatteriesBrands()
    this.props.getAllTyreBrands()
    this.props.getAllLubricants()
    this.props.getDeliveryTime({ type: "default" })
    const { getSocket } = this.context
    this.props.getHomepageSection()
    const currentUser = JSON.parse(localStorage.getItem('login'))
    if (currentUser?._id) {
      // currentUser?.role === "BUYER" && this.props.getHomepageSection(currentUser?._id);
      currentUser?.role === "BUYER" && this.props.setUserCart(currentUser?._id);
      getSocket(currentUser?._id)
      this.props.getUserDetail(currentUser?._id)
      this.props.getNotificationInital(currentUser?._id)
    }
    ReactGA.send('pageview')
  }

  componentWillUnmount() {
    this.context.disconnectSocket()
  }

  handleMoreMenu = (value) => {
    this.setState({ showMobileMenu: value });
    this.setState({ showMobileSearch: false });
  }
  handleBackFromSearch = (value) => {
    this.setState({ showMobileSearch: value });
    this.setState({ showMobileMenu: false });
  }


  render() {
    const currentPath = window.location.pathname;
    const { showMobileMenu, showMobileSearch } = this.state;
    const currentUser = JSON.parse(localStorage.getItem('login'))
    return (
      <>
        {
          this.props.settings.isOnMaintainance ?
            <Maintainance /> :
            <>
              {
                this.props.settings.isLoading ? null :
                  <>
                    <ToastContainer
                      position='bottom-center'
                      autoClose={5000}
                      hideProgressBar={true}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme='light'
                    />

                    <Router basename={process.env.PUBLIC_URL}>
                      {!(showMobileSearch || showMobileMenu) &&
                        <>
                          {/* <Disscussion /> */}
                          {/* <PolicyAggremementModal /> */}
                          <HeaderWrapper currentPath={currentPath}
                            handleFromSearch={(value) => this.handleBackFromSearch(value)} />
                          <div style={{marginTop: '13rem'}}>
                            <Switch>
                              <Route exact path='/' component={Index} />
                              <Route exact path='/chat' component={chats} />

                              <Route path='/how-it-works' component={HowItWorks} />
                              <Route path='/about-us' component={AboutUs} />
                              <Route path='/become-buyer' component={BecomeBuyer} />
                              <Route path='/become-seller' component={BecomeSeller} />
                              <Route path='/contact' component={ContactUs} />
                              <Route path='/get-vin' component={ImageRecog} />
                              <Route path='/privacy' component={PrivacyPolicy} />
                              <Route path='/refundprivacy' component={RefundPrivacy} />
                              <Route path='/faqs' component={FAQPage} />
                              <Route path='/help' component={Help} />
                              <Route path='/terms' component={TermsAndConditions} />
                              <Route path='/thankyou' component={Thankyou} />
                              <Route path='/thankyou-register' component={ThankyouRegister} />
                              <Route path='/all-listings' component={AllListing} />
                              <Route path='/parts-search-vin' component={SearchVin} />
                              <Route path='/tires-batteries' component={SearchTiresBatteries} />
                              <Route path='/news' component={News} />
                              <Route path='/json-info' component={JSONInfo} />

                              <Route path='/spare-parts/:category'>
                                <Route
                                  exact
                                  path='/spare-parts/:category'
                                  component={AllListing}
                                />
                                <Route
                                  exact
                                  path='/spare-parts/:category/:subcategory'
                                  component={AllListing}
                                />
                              </Route>
                              <Route
                                path='/order/einvoice-mobile/:order_id/:seller_id'
                                component={EinvoiceMobile}
                              />
                              <Route path='/car-parts/:make' component={AllListing} />
                              <Route path='/brand-list/:brand/:type' component={AllListing} />
                              <Route path='/purchase-list' component={PurchaseListing} />
                              <Route
                                exact
                                path='/spare-part-details/:info/:id'
                                component={ListingDetails}
                              />

                              <Route
                                exact
                                path='/spare-part-details/:info'
                                component={ListingDetails}
                              />

                              <Route path='/news-details/:id' component={NewsDetails} />

                              <RestrictedRoute path='/register' exact component={Register} />
                              <RestrictedRoute
                                path='/seller-register'
                                exact
                                component={SellerRegister}
                              />
                              <RestrictedRoute
                                path='/register/seller'
                                component={RegisterSeller}
                              />
                              <RestrictedRoute path='/register/buyer' component={RegisterBuyer} />
                              <RestrictedRoute
                                path='/register/verify-email'
                                component={VerifyEmail}
                              />
                              <RestrictedRoute
                                path='/register/verify-phone'
                                component={VerifyPhone}
                              />
                              <RestrictedRoute
                                path='/register/password'
                                component={PasswordForm}
                              />
                              <SellerPrivateRoute
                                path='/seller-deliveries'
                                component={SellerDeliveries}
                              />
                              <BuyerPrivateRoute
                                path='/buyer-deliveries'
                                component={BuyerDeliveries}
                              />

                              <BuyerPrivateRoute path='/credits' component={Credits} />

                              <BuyerPrivateRoute
                                path='/collections/:slug'
                                component={Collections}
                              />

                              <SellerPrivateRoute path='/sell-your-parts' component={AddParts} />
                              <SellerPrivateRoute path='/edit-parts/:id' component={EditParts} />
                              <PrivateRoute
                                exact
                                path='/stores/:slug'
                                component={SellerDetails}
                              />
                              <PrivateRoute path='/stores' component={SellerList} />
                              <PrivateRoute path='/checkout' component={Checkout} />

                              <PrivateRoute path='/request-part' component={RequestPart} />
                              <PrivateRoute path='/my' component={MyMessages} />
                              <PrivateRoute path='/orders/view/:slug' component={TrackingOrder} />
                              <PrivateRoute path='/rfq/view/:id' component={RfqDetails} />
                              <SellerPrivateRoute path='/payments' component={MyPayment} />
                              <SellerPrivateRoute path='/my-listings' component={MyListing} />
                              <PrivateRoute path='/my-profile' component={MyProfile} />
                              <PrivateRoute path='/settings' component={Settings} />
                              <PrivateRoute path='/my-store' component={MyStore} />
                              <PrivateRoute path='/my-dashboard' component={MyDashboard} />
                              <PrivateRoute path='/my-sales' component={MySales} />
                              <PrivateRoute path='/my-order' component={MyOrder} />
                              <PrivateRoute path='/quotations' component={Quotation} />
                              <PrivateRoute path='/my-request' component={MyRequest} />
                              {/* <PrivateRoute path="/tracking-order" component={TrackingOrder} /> */}

                              {/* <Route path="/my-favorites" component={MyFavorites} /> */}
                              {/* <PrivateRoute path="/my-callbacks" component={MyCallbacks} />  */}
                              {/* <PrivateRoute path="/cart" component={Cart} /> */}
                              <PrivateRoute path='/shipping' component={Shipping} />
                              <PrivateRoute
                                path='/your-orders-buyer'
                                component={YourOrdersBuyer}
                              />
                              <PrivateRoute
                                path='/your-orders-seller'
                                component={YourOrderSeller}
                              />
                              <PrivateRoute path='/order-details' component={OrderDetails} />
                              {/* <Route path="/order-details" component={OrderDetails} /> */}
                              <PrivateRoute path='/reviews' component={MyReview} />
                              {/* private routes */}
                              <SellerPrivateRoute path='/payments' component={MyPayment} />
                              <SellerPrivateRoute path='/my-listings' component={MyListing} />
                              <PrivateRoute path='/my-profile' component={MyProfile} />
                              <PrivateRoute path='/settings' component={Settings} />
                              <PrivateRoute path='/my-store' component={MyStore} />
                              <PrivateRoute path='/my-dashboard' component={MyDashboard} />
                              {/* <PrivateRoute path="/my-message" component={MyMessages} /> */}
                              <PrivateRoute path='/my-order' component={MyOrder} />
                              <PrivateRoute path='/quotations' component={Quotation} />
                              <PrivateRoute path='/my-request' component={MyRequest} />
                              <PrivateRoute path='/your-address' component={YourAddress} />
                              <PrivateRoute path='/payment' component={OnlinePayment} />
                              <PrivateRoute path='/3ds-payment' component={ThreeDsPayment} />
                              <PrivateRoute path='/coming-soon' component={ComingSoon} />
                              {/* <PrivateRoute path="/reviews" component={MyReview} /> */}
                              <PrivateRoute path='/messages' component={Chats} />
                              <SellerPrivateRoute path='/sell-your-parts' component={AddParts} />
                              {/* <Route path="/my-favorites" component={MyFavorites} />
                    <Route path="/my-callbacks" component={MyCallbacks} /> */}
                              {/* <PrivateRoute path="/reviews" component={MyReview} /> */}
                              <Route path='*' component={NotFound} />
                            </Switch>
                          </div>
                          <Footer />
                        </>}
                      <MobileSearch showMobileSearch={showMobileSearch}
                        handleBackFromSearch={(value) => this.handleBackFromSearch(value)} />
                      {
                        currentUser &&
                        <>
                          <MobileMenu showMobileMenu={showMobileMenu}
                            loggedUser={currentUser?.details?.emirate}
                            moreMobileFooter={(value) => this.handleMoreMenu(value)}
                          />
                          <MobileFooter moreMobileFooter={(value) => this.handleMoreMenu(value)} />
                        </>
                      }
                    </Router>
                  </>
              }
            </>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    logOutdata: (login) => dispatch(LogOut(login)),
    setDefaultAddress: (address) => dispatch(SetDefaultAddress(address)),
    GetBannerList: () => dispatch(getBannerList()),
    getAllCategories: () => dispatch(getAllCategories()),
    getNotificationInital: (userId) => dispatch(getNotificationInital(userId)),
    updateUserInfo: (data) => dispatch(UpdateUserInfo(data)),
    getAllBrands: () => dispatch(getAllBrands()),
    getAllLubricants: () => dispatch(getAllLubricants()),
    getHomepageSection: () => dispatch(getHomepageSection()),
    getUserDetail: (userId) => dispatch(getUserDetail(userId)),
    getAllStates: () => dispatch(getAllStates()),
    setUserCart: (userId) => dispatch(setUserCart(userId)),
    getAllTyreBrands: () => dispatch(getCatalogBrands({
      filter: "?type=tires",
      type: "GET_TIRE_BRAND_DATA_SUCCESS"
    })),
    getAllBatteriesBrands: () => dispatch(getCatalogBrands({
      filter: "?type=batteries",
      type: "GET_BATTERY_BRAND_DATA_SUCCESS"
    })),
    getAllLubricantsBrands: () => dispatch(getAllLubricants({
      filter: "?type=lubricants",
      type: "GET_ALL_LUBS_SUCCESS"
    })),
    getDeliveryTime: (data) => dispatch(getDeliveryTime(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProp)(App)
