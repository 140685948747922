import React, { Component, Fragment } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import equal from 'fast-deep-equal'
import { withTranslation } from 'react-i18next'
import { Box, Drawer } from '@material-ui/core'

import ListingCardGrid from '../content/element/card/card-listing-grid'
import { OptionSelection } from '../content/element/filter/selection'
import {
  ga4Events,
  numberWithCommas,
  popUp,
  handleBannerEvents,
} from '../../utils'
import { Initialize, SetListings } from '../../Store/action/listingActions'
import Leaderboard from '../content/element/leaderboard'
import LoginModal from '../content/Login/LoginModal'
import { PartsComponent } from '../content/element/sidebar/SidebarFilter'
import MakeListData from './MakeListData'

const businessModel = [
  { _id: 'Consolidation', name: 'Consolidation' },
  { _id: 'Fulfilment', name: 'Sold by BP24' },
]
class Listing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allCategories: null,
      allSubCategoriesBatteries: null,
      list: [],
      emiratesData: [],
      count: 0,
      perPage: 20,
      data: {},
      searchText: '',
      orderText: 'Recommended',
      isPartBrandSelect: false,
      isDrawerOpen: false,
      width: 0,
      searchQuery: {
        search: '',
        priceRange: null,
        type: null,
        category: null,
        tireCats: null,
        tireSubCats: null,
        subCategory: null,
        subcategory: null,
        make: null,
        vinCode: null,
        emirates: null,
        businessModel: null,
        year: null,
        brands: null,
        sortBy: {},
        model: null,
        amp: null,
        voltage: null,
        battery_year: null,
        width: null,
        height: null,
        rim_size: null,
        tire_year: null,
        categoryLists: [],
        subCategoryLists: [],
        listingType: null,
        file_id: null,
        isVINSearch: null,
        partname: '',
        partnumber: '',
        filterFromSidebar: false
      },
      pagemake: 0,
      pagemodel: 0,
      pagebrand: 0,
      isModalOpen: false,
      redirectURL: '',
      arrModelList: []
    }

    this.handleSliderChange = this.handleSliderChange.bind(this)
    this.handleSelectionChange = this.handleSelectionChange.bind(this)
    this.handleSliderAfterChange = this.handleSliderAfterChange.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.handleFilteredData = this.handleFilteredData.bind(this)
    this.handleBrandclick = this.handleBrandclick.bind(this)
    this.handleGaEvent = this.handleGaEvent.bind(this)
    this.handleDrawer = this.handleDrawer.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleForLogin = this.toggleForLogin.bind(this)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    this.setState({
      searchQuery: {
        ...this.props.list.searchQuery,
      },
    })
  }

  handleFilteredData = (data) => {
    this.setState({
      searchQuery: data,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(prevProps.list.searchQuery, this.props.list.searchQuery)) {
      this.setState({ allCategories: this.props.allCategories })
      this.setState({
        searchQuery: {
          ...this.props.list.searchQuery,
        },
        orderText: 'Recommended',
      })
    }
  }

  handleSliderChange(values, name) {
    let { searchQuery } = this.state
    searchQuery[name] = values
    this.setState({ searchQuery })
  }

  handleSliderAfterChange() {
    const { searchQuery } = this.state
    this.props.handleFilter(searchQuery)
  }

  handleBrandclick = () => {
    this.setState({ isPartBrandSelect: true })
  }

  handleSelectionChange(options, name) {
    let { searchQuery } = this.state
    searchQuery[name] = options;
    const all = [].concat(searchQuery.businessModel, searchQuery.make, searchQuery.emirates, searchQuery.brands, searchQuery.category, searchQuery.subCategory, searchQuery.model || [])?.filter(item => item !== undefined && item !== null)
    all.length ? (searchQuery["filterFromSidebar"] = true) : delete searchQuery.filterFromSidebar;
    this.setState({ searchQuery })
    this.props.handleFilter(searchQuery);
    if (name === 'make') {
      const arrOptions = options.filter(item => item.id_car_make).map(obj => obj.id_car_make)
      const arrModelList = this.props.list.modelList.filter(res => arrOptions.includes(res.id_car_make));
      this.setState((prevState) => ({
        ...prevState, arrModelList
      }))
    }
  }

  resetFilters = (e) => {
    e.preventDefault()
    const initQuery = {
      search: '',
      priceRange: null,
      type: null,
      category: null,
      tireCats: null,
      tireSubCats: null,
      subCategory: null,
      subcategory: null,
      make: null,
      vinCode: null,
      emirates: null,
      businessModel: null,
      year: null,
      brands: null,
      sortBy: {},
      model: null,
      amp: null,
      voltage: null,
      batteryYear: null,
      width: null,
      height: null,
      rimSize: null,
      tireYear: null,
      categoryLists: [],
      subCategoryLists: [],
      listingType: null,
      file_id: null,
      isVINSearch: null,
      filterFromSidebar: false
    }
    this.setState({ searchQuery: initQuery })
    if (this.props.list?.page === 'listing') {
      const url = this.props.location
      url?.pathname === '/all-listings' && !url?.search
        ? this.props.initializeListing()
        : this.props.history.replace(`/all-listings`, {})
    }
    if (this.props.list?.page === 'collection') {
      this.props.history.push(this.props.slug)
      this.props.handleFilter(initQuery, 'reset')
    }
  }

  resetSort = () => {
    this.setState({
      orderText: 'Recommended',
    })
  }

  handleSearchVin = (e) => {
    e.preventDefault()
    const searchText = this.state.searchText
    const dataToSend = { ...this.state.searchQuery, vinCode: searchText }
    this.setState({ searchQuery: dataToSend })
    this.props.handleFilter(dataToSend)
  }

  setpagemake = (pageno) => {
    this.setState({ pagemake: pageno })
  }

  setpagemodel = (pageno) => {
    this.setState({ pagemodel: pageno })
  }
  setpagebrnd = (pageno) => {
    this.setState({ pagebrand: pageno })
  }

  handleGaEvent = (id) => {
    ga4Events({
      action: 'product_list_page_banner_click',
      category: 'Banner',
      banner_id: id,
    })
  }

  handleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
  }

  //toggle for login
  toggle = (e, banner) => {
    e.preventDefault()
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      redirectURL: banner,
    })
  }

  toggleForLogin = () => {
    this.setState({
      isModalOpen: false,
    })
  }

  render() {
    let searchString = ''
    let searchMakeString = ''
    let searchModelString = ''

    const unique = (arr) =>
      arr?.length
        ? arr
          ?.map((e) => e['_id'])
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((obj) => arr[obj])
          .map((e) => arr[e])
        : []

    const { t, extraParams, isAfterloading } = this.props
    const currentUser = JSON.parse(localStorage.getItem('login'))

    // sorting here
    const sort = []
    Object.values(this.state.list).map((item) => {
      return sort.push(item)
    })
    let top_leaderboard_category_banner = this.props?.list?.bannersList?.find(
      (banner) =>
        banner?.archived === false &&
        banner.category === 'top_leaderboard_category_banner'
    )
    let bottom_leaderboard_category_banner =
      this.props?.list?.bannersList?.find(
        (banner) =>
          banner?.archived === false &&
          banner.category === 'bottom_leaderboard_category_banner'
      )
    let mpu_sidebar_category_banner = this.props?.list.bannersList
      ?.filter(
        (banner) =>
          banner?.archived === false &&
          banner.category === 'mpu_sidebar_category_banner'
      )
      ?.sort((a, b) => a.position - b.position)

    const sorting = (e) => {
      e.preventDefault()
      let { searchQuery } = this.state
      switch (e.target.getAttribute('href')) {
        case 'lowest-price':
          searchQuery['sortBy'] = { price: 1 }
          this.setState({ orderText: 'Lowest Price' })
          break
        case 'highest-price':
          searchQuery['sortBy'] = { price: -1 }
          this.setState({ orderText: 'Highest Price' })
          break
        case 'new':
          searchQuery['sortBy'] = { date: -1 }
          this.setState({ orderText: 'New To Old' })
          break
        case 'recommended':
          searchQuery['sortBy'] = { recommended: 1 }
          this.setState({ orderText: 'Recommended' })
          break
        default:
          searchQuery['sortBy'] = { date: 1 }
          this.setState({ orderText: 'Old To New' })
          break
      }
      this.setState({ searchQuery })
      this.props.handleFilter(searchQuery, 'sort')
    }
    // sorting end
    const { searchQuery, orderText, changedCheckItems, isPartBrandSelect } =
      this.state
    const {
      maxPrice,
      minPrice,
      makeList,
      modelList,
      categoryList,
      subCategoriesList,
      listing,
      brandList,
      sparePartSearchDetails,
    } = this.props.list

    const businessModelOption = businessModel.map((item) => {
      return {
        _id: item?._id,
        name: item?.name,
      }
    })

    const emirateListsOptions = this.props?.states?.map((item) => {
      return {
        _id: item?._id,
        name: item?.name,
      }
    })
    let makeListsOptions = makeList?.map((item) => {
      return {
        _id: item?.name,
        name: item?.name,
        id_car_make: item?.id_car_make
      }
    })
    const sortOrder = searchQuery?.make;
    if(sortOrder) {
      makeListsOptions = makeListsOptions.sort((a, b) => {
        const isAInSortOrder = sortOrder.some(obj => obj.name === a.name);
        const isBInSortOrder = sortOrder.some(obj => obj.name === b.name);
  
        if(isAInSortOrder) return -1;
        if(isBInSortOrder) return 1;
  
        return 0;
      })
    }


    const modelListData = this.state.arrModelList && this.state.arrModelList.length ? this.state.arrModelList : modelList;
    const modelListsOptions = modelListData?.map((item) => {
      return {
        _id: item?.name,
        name: item?.name,
      }
    })

    const partBrandListsOptions = brandList?.map((item) => {
      return {
        _id: item?.toLowerCase(),
        name: item,
      }
    })

    const listData = this.props.list.listing;
    let categoryListsFiltered = {};
    if (listData) {
      categoryListsFiltered = listData.reduce((acc, curr) => {
        if (!acc.category.includes(curr.category)) {
          acc.category.push(curr.category);
        }
        if (!acc.subCategory.includes(curr.subCategory)) {
          acc.subCategory.push(curr.subCategory);
        }
        return acc;
      }, { category: [], subCategory: [] });
    }

    const partCategoryListsOptions = this.props.allCategories;
    // const partCategoryLists = this.props.allCategories;
    // const partCategoryListsOptions = partCategoryLists.filter(item => categoryListsFiltered.category.includes(item._id))
    // .map(item => ({
    //     ...item, subCategories: item.subCategories && item.subCategories.filter(sub => categoryListsFiltered.subCategory.includes(sub._id))
    //   }));
    const categoryListsOptions = categoryList?.map((item) => {
      return {
        _id: item?._id,
        name: item?.name,
      }
    })

    const subCategoryListOptions = subCategoriesList?.map((item) => {
      return {
        _id: item?._id,
        name: item?.name,
      }
    })

    const filteredMake = unique(makeListsOptions)?.slice(
      this.state.pagemake * 10,
      this.state.pagemake * 10 + 10
    )

    const filteredModel = unique(modelListsOptions)?.slice(
      this.state.pagemodel * 10,
      this.state.pagemodel * 10 + 10
    )

    const filteredBrand = unique(partBrandListsOptions)?.slice(
      this.state.pagebrand * 10,
      this.state.pagebrand * 10 + 10
    )

    const totalpagemodel = Math.ceil(unique(modelListsOptions)?.length / 10)
    const totalpagemake = Math.ceil(unique(makeListsOptions)?.length / 10)
    const totalpagebrand = Math.ceil(unique(partBrandListsOptions)?.length / 10)
    let searchMakeQuerys = filteredMake?.filter(item => {
      searchMakeString = searchQuery?.partname?.toLowerCase().split(' ')
      let containsAtLeastOneWord = false;
      searchMakeString?.forEach(word => {
        if (item._id.toLowerCase().includes(word))
          containsAtLeastOneWord = true;
      })
      if (containsAtLeastOneWord) {
        console.log('MAke>>>' + item)
        return item
      }
    })
    let searchModelsQuerys = filteredModel?.filter(item => {
      searchModelString = searchQuery?.partname?.toLowerCase().split(' ')
      let containsAtLeastOneWord = false;
      searchModelString?.forEach(word => {
        if (item._id.toLowerCase().includes(word))
          containsAtLeastOneWord = true;
      })
      if (containsAtLeastOneWord) {
        console.log('Model>>>' + item)
        return item
      }
    })
    let searchBrandQuerys = filteredBrand?.filter(item => {
      searchString = searchQuery?.partname?.toLowerCase().split(' ')
      let containsAtLeastOneWord = false;
      // If at least a word is matched return it!
      searchString?.forEach(word => {
        if (item._id.toLowerCase().includes(word))
          containsAtLeastOneWord = true;
      })
      if (containsAtLeastOneWord) {
        console.log('Brand>>>' + item)
        return item
      }
    })

    return (
      <Fragment>
        <section className='all-listing-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              {
                <div className='col-lg-2 order-lg-0 order-0'>
                  {this.props.list.page !== 'collection' &&
                    mpu_sidebar_category_banner?.length > 0 && (
                      <>
                        {mpu_sidebar_category_banner?.map((banner, index) => {
                          return (
                            <div
                              className='hide-banner'
                              onClick={(e) => {
                                this.handleGaEvent(banner?._id)
                                !currentUser &&
                                  this.toggle(e, banner?.banner_link)
                              }}
                              style={{
                                alignContent: 'center',
                                width: '385px',
                                backgroundSize: 'cover',
                                objectPosition: 'center',
                                objectFit: 'cover',
                                justifyContent: 'center',
                                marginBottom: '20px',
                                borderRadius: '10px',
                                zIndex: 3,
                              }}>
                              <a href={currentUser && banner?.banner_link}>
                                <img
                                  className='top-M-content'
                                  style={{
                                    borderRadius: '10px',
                                    height: '175px',
                                  }}
                                  src={banner?.banner_upload}
                                  alt='banner'
                                />
                              </a>
                            </div>
                          )
                        })}
                      </>
                    )}
                  <LoginModal
                    isModalOpen={this.state.isModalOpen}
                    toggle={this.toggle}
                    loginToggle={this.toggleForLogin}
                    redirectURL={this.state.redirectURL}
                  />
                  {
                    <div
                      className='listings-sidebar'
                      style={{ marginTop: '10px' }}>
                      <div className='search-area default-ad-search'>
                        <div
                          className='search-area-header'
                          style={{ color: 'black' }}>
                          {t('all_listings_filters')}
                          <p
                            style={{
                              display: 'inline-flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center',
                              gap: 2,
                              borderBottom: '1px solid black',
                              padding: 0,
                              margin: 0,
                            }}
                            onClick={(e) => this.resetFilters(e)}>
                            <span
                              style={{
                                border: '1px solid black',
                                borderRadius: '50%',
                                width: '13px',
                                height: '13px',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                color: 'black',
                                padding: 0,
                                margin: 0,
                              }}>
                              <span style={{ fontSize: '9px' }}>X</span>
                            </span>
                            <span
                              style={{
                                color: 'black',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                padding: 0,
                                margin: 0,
                              }}>
                              Clear Filters
                            </span>
                          </p>
                        </div>

                        <div
                          className='accordion'
                          id='filterAccordion'
                          role='tablist'
                          aria-multiselectable='true'>
                          <div className='card'>
                            <div
                              className='card-header'
                              role='tab'
                              id='headingOne6'>
                              <a
                                data-toggle='collapse'
                                href='#collapseOne6'
                                aria-expanded='true'
                                aria-controls='collapseOne6'>
                                <span className='mb-0'>
                                  Business Model
                                  {`${searchQuery.businessModel?.length > 0
                                    ? ' (' +
                                    searchQuery.businessModel?.length +
                                    ')'
                                    : ''
                                    }`}{' '}
                                </span>
                                <i className='la la-angle-up'></i>
                              </a>
                            </div>

                            <div
                              id='collapseOne6'
                              className='collapse show'
                              role='tabpanel'
                              aria-labelledby='headingOne6'>
                              <OptionSelection
                                options={businessModelOption || []}
                                name='businessModel'
                                activeOptions={
                                  searchQuery.businessModel
                                    ? searchQuery.businessModel
                                    : []
                                }
                                onChange={this.handleSelectionChange}
                              />
                            </div>
                          </div>
                          {/*  <div className='card'>
                            <div
                              className='card-header'
                              role='tab'
                              id='headingOne8'>
                              <a
                                data-toggle='collapse'
                                href='#collapseOne8'
                                aria-expanded='true'
                                aria-controls='collapseOne8'
                              // className="collapsed"
                              >
                                <span className='mb-0'>
                                  {t('Emirates')}{' '}
                                  {`${searchQuery.emirates?.length > 0
                                    ? ' (' +
                                    searchQuery.emirates?.length +
                                    ')'
                                    : ''
                                    }`}
                                </span>
                                <i className='la la-angle-up'></i>
                              </a>
                            </div>
                            <div
                              id='collapseOne8'
                              className='collapse show'
                              role='tabpanel'
                              aria-labelledby='headingOne3'>
                              <OptionSelection
                                options={emirateListsOptions || []}
                                name='emirates'
                                activeOptions={
                                  searchQuery.emirates
                                    ? searchQuery.emirates
                                    : []
                                }
                                onChange={this.handleSelectionChange}
                              />
                            </div>
                          </div*/}
                          {this.state.searchQuery?.type == null ||
                            (this.state.searchQuery?.type === 'batteries' &&
                              searchQuery.categoryLists?.length && (
                                <div className='card'>
                                  <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne6'>
                                    <a
                                      data-toggle='collapse'
                                      href='#collapseOne6'
                                      aria-expanded='true'
                                      aria-controls='collapseOne6'
                                    // className="collapsed"
                                    >
                                      <span className='mb-0'>
                                        Category
                                        {`${searchQuery.categoryLists?.length > 0
                                          ? ' (' +
                                          searchQuery.categoryLists
                                            ?.length +
                                          ')'
                                          : ''
                                          }`}
                                      </span>
                                      <i className='la la-angle-up'></i>
                                    </a>
                                  </div>
                                  <div
                                    id='collapseOne6'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne6'>
                                    <OptionSelection
                                      options={categoryListsOptions || []}
                                      name='categoryLists'
                                      activeOptions={
                                        searchQuery.categoryLists
                                          ? searchQuery.categoryLists
                                          : []
                                      }
                                      onChange={this.handleSelectionChange}
                                    />
                                  </div>
                                </div>
                              ))}
                          {this.state.searchQuery?.type === 'batteries' &&
                            searchQuery.subCategory?.length && (
                              <div className='card'>
                                <div
                                  className='card-header'
                                  role='tab'
                                  id='headingOne6'>
                                  <a
                                    data-toggle='collapse'
                                    href='#collapseOne6fsfesf'
                                    aria-expanded='true'
                                    aria-controls='collapseOne6fsfesf'
                                  // className="collapsed"
                                  >
                                    <span className='mb-0'>
                                      Sub Category
                                      {`${searchQuery.subCategory?.length > 0
                                        ? ' (' +
                                        searchQuery.subCategory?.length +
                                        ')'
                                        : ''
                                        }`}
                                    </span>
                                    <i className='la la-angle-up'></i>
                                  </a>
                                </div>
                                <div
                                  id='collapseOne6fsfesf'
                                  className='collapse show'
                                  role='tabpanel'
                                  aria-labelledby='headingOne6'>
                                  <OptionSelection
                                    options={subCategoryListOptions}
                                    name='subCategoryLists'
                                    activeOptions={
                                      searchQuery.subCategoryLists
                                        ? searchQuery.subCategoryLists
                                        : []
                                    }
                                    onChange={this.handleSelectionChange}
                                  />
                                </div>
                              </div>
                            )}

                          {this.state.searchQuery?.type == 'tires' &&
                            searchQuery.tireCats?.length && (
                              <>
                                <div className='card'>
                                  <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne6'>
                                    <a
                                      data-toggle='collapse'
                                      href='#collapseOne7'
                                      aria-expanded='true'
                                      aria-controls='collapseOne7'
                                    // className="collapsed"
                                    >
                                      <span className='mb-0'>
                                        Category
                                        {`${searchQuery.tireCats?.length > 0
                                          ? ' (' +
                                          searchQuery.tireCats?.length +
                                          ')'
                                          : ''
                                          }`}
                                      </span>
                                      <i className='la la-angle-up'></i>
                                    </a>
                                  </div>
                                  <div
                                    id='collapseOne7'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne6'
                                  // data-parent="#filterAccordion"
                                  >
                                    <OptionSelection
                                      options={categoryListsOptions}
                                      name='categoryLists'
                                      activeOptions={
                                        searchQuery.categoryLists
                                          ? searchQuery.categoryLists
                                          : []
                                      }
                                      onChange={this.handleSelectionChange}
                                    />
                                  </div>
                                </div>

                                <div className='card'>
                                  <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne6'>
                                    <a
                                      data-toggle='collapse'
                                      href='#collapseOne11'
                                      aria-expanded='true'
                                      aria-controls='collapseOne11'
                                    // className="collapsed"
                                    >
                                      <span className='mb-0'>
                                        Sub Category
                                        {`${searchQuery.tireSubCats?.length > 0
                                          ? ' (' +
                                          searchQuery.tireSubCats?.length +
                                          ')'
                                          : ''
                                          }`}
                                      </span>
                                      <i className='la la-angle-up'></i>
                                    </a>
                                  </div>
                                  <div
                                    id='collapseOne11'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne6'>
                                    <OptionSelection
                                      options={subCategoryListOptions}
                                      name='subCategoryLists'
                                      activeOptions={
                                        searchQuery.subCategoryLists
                                          ? searchQuery.subCategoryLists
                                          : []
                                      }
                                      onChange={this.handleSelectionChange}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                        {this.props.match.params.category &&
                          <PartsComponent
                            data={partCategoryListsOptions}
                            options={searchQuery}
                            state={this.props.match.params.category}
                            setState={this.handleSelectionChange}
                          />
                        }
                          <div className='card'>
                            <div
                              className='card-header'
                              role='tab'
                              id='headingOne1'>
                              <a
                                data-toggle='collapse'
                                data-parent='#filterAccordion'
                                href='#collapseOne1'
                                aria-expanded='true'
                                aria-controls='collapseOne1'>
                                <span className='mb-0'>{t('price')}</span>
                                <i className='la la-angle-up'></i>
                              </a>
                            </div>
                            <div
                              id='collapseOne1'
                              className='collapse show'
                              role='tabpanel'
                              aria-labelledby='headingOne1'
                              data-parent='#filterAccordion'>
                              <div className='card-body'>
                                <div className='form-group'>
                                  <div className='price-range rs-primary'>
                                    <p className='d-flex justify-content-between'>
                                      <span className='amount'>
                                        {searchQuery.priceRange
                                          ? `${numberWithCommas(
                                            searchQuery.priceRange[0]
                                          )}${this.props.currency.currency
                                          } - ${numberWithCommas(
                                            searchQuery.priceRange[1]
                                          )}${this.props.currency.currency} `
                                          : `${numberWithCommas(minPrice)}${this.props.currency.currency
                                          }  - ${numberWithCommas(maxPrice)}${this.props.currency.currency
                                          } `}
                                      </span>
                                    </p>

                                    <Range
                                      min={minPrice}
                                      max={maxPrice}
                                      defaultValue={[minPrice, maxPrice]}
                                      value={
                                        searchQuery.priceRange
                                          ? searchQuery.priceRange
                                          : [minPrice, maxPrice]
                                      }
                                      onChange={(values) => {
                                        this.handleSliderChange(
                                          values,
                                          'priceRange'
                                        )
                                      }}
                                      onAfterChange={
                                        this.handleSliderAfterChange
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
   {this.state.searchQuery && this.state.searchQuery.isVINSearch &&
                          <PartsComponent
                            data={partCategoryListsOptions}
                            options={searchQuery}
                            state={this.state}
                            setState={this.handleSelectionChange}
                          />
                        }


                          {filteredMake?.length > 0 && (
                            <div className='card'>
                              <div
                                className='card-header'
                                role='tab'
                                id='headingOne3'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne3'
                                  aria-expanded='true'
                                  aria-controls='collapseOne3'>
                                  <span className='mb-0'>
                                    {t('make')}
                                    {`${searchQuery.make?.length > 0
                                      ? ' (' +
                                      unique(searchQuery.make)?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-up'></i>
                                </a>
                              </div>
                              <MakeListData
                                searchQuery={searchQuery}
                                filteredMake={filteredMake}
                                handleSelectionChange={this.handleSelectionChange}
                                pagemake={this.state.pagemake}
                                setpagemake={this.setpagemake}
                                totalpagemake={totalpagemake}
                                makeListsOptions={makeListsOptions}
                                modelList={this.props.list.modelList}
                                arrModelListChange={(arrModelList) => this.setState((prevState) => ({
                                  ...prevState, arrModelList
                                }))}
                              />
                              {/* <div
                                id='collapseOne3'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='heaOptionSelectiondingOne3'>
                                <OptionSelection

                                  options={searchQuery.isVINSearch ? searchQuery?.make :filteredMake}
                                  page={this.state.pagemake}

                                  setpage={this.setpagemake}
                                  totalpage={totalpagemake}
                                  show={true}
                                  name='make'
                                  activeOptions={
                                  searchQuery?.make  ? getActiveMakeList(searchQuery.make) : []
                                  }
                                  onChange={this.handleSelectionChange}
                                />
                              </div> */}
                            </div>
                          )}

                          {filteredModel?.length > 0 && (
                            <div className='card'>
                              <div
                                className='card-header'
                                role='tab'
                                id='headingOne4'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne4'
                                  aria-expanded='true'
                                  aria-controls='collapseOne4'>
                                  <span className='mb-0'>
                                    {'Model'}
                                    {`${searchQuery.model?.length > 0
                                      ? ' (' +
                                      unique(searchQuery.model)?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-up'></i>
                                </a>
                              </div>
                              <div
                                id='collapseOne4'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne4'>
                                <OptionSelection
                                  options={searchQuery.isVINSearch ? searchQuery?.model : filteredModel}

                                  page={this.state.pagemodel}
                                  setpage={this.setpagemodel}
                                  totalpage={totalpagemodel}
                                  show={true}
                                  name='model'
                                  activeOptions={
                                    searchQuery?.model ? searchQuery.model : []
                                  }
                                  onChange={this.handleSelectionChange}
                                />
                              </div>
                            </div>
                          )}

                          {filteredBrand?.length > 0 && (
                            <div className='card'>
                              <div
                                className='card-header'
                                role='tab'
                                id='headingOne5'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne5'
                                  aria-expanded='true'
                                  aria-controls='collapseOne5'>
                                  <span className='mb-0'>
                                    Part Brands
                                    {`${searchQuery.brands?.length > 0
                                      ? ' (' +
                                      unique(searchQuery.brands)?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-up'></i>
                                </a>
                              </div>

                              <div
                                id='collapseOne5'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne5'>
                                <OptionSelection
                                  options={filteredBrand}
                                  page={this.state.pagebrand}
                                  setpage={this.setpagebrnd}
                                  totalpage={totalpagebrand}
                                  show={true}
                                  name='brands'
                                  activeOptions={
                                    searchQuery && searchQuery?.brands
                                      ? searchQuery?.brands
                                      : searchBrandQuerys

                                  }
                                  onChange={this.handleSelectionChange}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                  }
                </div>
              }
              <div className={'col-lg-10 order-lg-1 order-1'}>
                {this.props.list.page !== 'collection' &&
                  top_leaderboard_category_banner && (
                    <Leaderboard
                      banner={top_leaderboard_category_banner}
                      listing={true}
                    />
                  )}
                <div className='row'>
                  <div className='col-lg-12'>
                    <div
                      className='atbd_listing_action_toolbar mobile-view'
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        marginBottom:
                          !(
                            this.props.category ||
                            searchQuery.search?.length > 0
                          ) && '15px',
                      }}>
                      <p
                        className='show-mobile'
                        style={{
                          margin: 20,
                          fontSize: 18,
                          fontWeight: 'bold',
                          color: 'black',
                        }}>
                        {this.props.list?.page === 'collection' &&
                          this.props.list?.collection &&
                          this.props.list?.collection?.title ? (
                          `Results For '${this.props.list?.collection?.title}'`
                        ) : (
                          <>
                            {extraParams?.extraData?.vinSearch
                              ? `Find Parts for ${extraParams?.extraData?.makeText || ''} 
                              ${extraParams?.extraData?.modeltitleText || ''} `
                              : this.props.category ||
                                searchQuery.search?.length > 0
                                ? 'Find Spare Parts for '
                                : '  '}
                            {this.props.api ? (
                              <span
                                style={{
                                  color: '#1e71b8',
                                  fontSize: '14px',
                                }}>
                                {sparePartSearchDetails?.make &&
                                  `Make:${sparePartSearchDetails?.make}`}{' '}
                                {sparePartSearchDetails?.model &&
                                  `Model:${sparePartSearchDetails?.model}`}{' '}
                                {sparePartSearchDetails?.engine &&
                                  `Engine:${sparePartSearchDetails?.engine}`}{' '}
                              </span>
                            ) : (
                              <span style={{ whiteSpace: 'pre-wrap' }}>
                                {this.props.category || searchQuery.search}
                              </span>
                            )}
                          </>
                        )}
                      </p>
                      <div className='dropdown'></div>

                      {
                        <>
                          <div
                            className='dropdown'
                            style={{ display: 'flex', float: 'right' }}>
                            <button
                              style={{
                                background: '#fff',
                                backgroundColor: '#fff',
                                color: '#000',
                                fontSize: '14px',
                                fontWeight: '500',
                                border: '1px solid black',
                                padding: '6px',
                                borderRadius: '5px',
                                marginTop: '17px',
                              }}
                              className='dropdown-toggle'
                              type='button'
                              id='dropdownMenuButton'
                              data-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'>
                              Sort By:{' '}
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                }}>
                                {this.state.orderText
                                  ? this.state.orderText
                                  : 'Recommended'}
                              </span>
                              <span>
                                <i className='la la-angle-up'></i>
                              </span>
                            </button>
                            <div className='hide-filter'>
                              <button
                                className='dropdown-toggle'
                                style={{
                                  background: '#fff',
                                  backgroundColor: '#fff',
                                  color: '#000',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  border: '1px solid black',
                                  padding: '6px 10px',
                                  borderRadius: '5px',
                                  marginLeft: '3px',
                                  marginTop: '17px',
                                }}
                                onClick={() => this.handleDrawer()}>
                                Filters
                              </button>
                            </div>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button
                                className='dropdown-item'
                                style={{
                                  fontWeight:
                                    this.state.orderText === 'Recommended' &&
                                    'bold',
                                  fontSize: '14px',
                                  color: 'black',
                                }}
                                type='button'
                                href='recommended'
                                onClick={sorting}>
                                {' '}
                                Recommended
                              </button>
                              <button
                                className='dropdown-item'
                                style={{
                                  fontWeight:
                                    this.state.orderText === 'Lowest Price' &&
                                    'bold',
                                  fontSize: '14px',
                                  color: 'black',
                                }}
                                type='button'
                                onClick={sorting}
                                href='lowest-price'>
                                {' '}
                                Lowest Price
                              </button>
                              <button
                                className='dropdown-item'
                                type='button'
                                style={{
                                  fontWeight:
                                    this.state.orderText === 'Highest Price' &&
                                    'bold',
                                  fontSize: '14px',
                                  color: 'black',
                                }}
                                onClick={sorting}
                                href='highest-price'>
                                Highest Price
                              </button>
                              <button
                                className='dropdown-item'
                                type='button'
                                style={{
                                  fontWeight:
                                    this.state.orderText === 'New To Old' &&
                                    'bold',
                                  fontSize: '14px',
                                  color: 'black',
                                }}
                                onClick={sorting}
                                href='new'>
                                {' '}
                                New to Old
                              </button>
                              <button
                                className='dropdown-item'
                                type='button'
                                style={{
                                  fontWeight:
                                    this.state.orderText === 'Old To New' &&
                                    'bold',
                                  fontSize: '14px',
                                  color: 'black',
                                }}
                                onClick={sorting}
                                href='old'>
                                {' '}
                                Old to New
                              </button>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div
                  className='row listing-5cards-wrapper'
                  style={{ display: 'block' }}>
                  <ListingCardGrid
                    size={3}
                    api={this.props.api}
                    handleFilter={this.props.handleFilter}
                    _searchQuery={this.state.searchQuery}
                    handleFilteredData={this.handleFilteredData}
                    handleBrandclick={this.handleBrandclick}
                  />
                  {this.props.list.page !== 'collection' &&
                    bottom_leaderboard_category_banner && (
                      <Leaderboard
                        banner={bottom_leaderboard_category_banner}
                      />
                    )}
                </div>
              </div>
            </div>

            <Box sx={{ display: 'flex' }}>
              <Box
                component='nav'
                sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}>
                <Drawer
                  anchor={'bottom'}
                  isVisible={this.state.isDrawerOpen}
                  open={this.state.isDrawerOpen}
                  onClose={() => this.handleDrawer()}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}>
                  {
                    <div
                      className='listings-sidebar'
                      style={{ height: '300px' }}>
                      <div className='search-area default-ad-search'>
                        <div
                          className='search-area-header'
                          style={{
                            color: 'black',
                            justifyContent: 'space-between',
                            display: 'flex',
                            fontWeight: 'bold',
                            marginBottom: '10px',
                          }}>
                          <span style={{ marginTop: '8px' }}>
                            {t('all_listings_filters')}
                          </span>
                          <>
                            <div style={{ display: 'flex' }}>
                              <NavLink to={`/all-listings`}>
                                <p
                                  style={{
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    gap: 2,
                                    padding: 0,
                                    margin: 0,
                                    marginTop: '2px',
                                  }}
                                  onClick={(e) => this.resetFilters(e)}>
                                  <span
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      alignContent: 'center',
                                      padding: 0,
                                      margin: 0,
                                    }}>
                                    <u>Clear Filters</u>
                                  </span>
                                </p>
                              </NavLink>
                              <div className='dropdown'>
                                <div className='hide-filter'>
                                  <button
                                    className='dropdown-toggle'
                                    style={{
                                      background: 'transparent',
                                      color: 'black',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      border: '1px solid black',
                                      borderRadius: '4px',
                                      marginRight: '2px',
                                      marginBottom: '3px',
                                      marginLeft: '10px',
                                      marginTop: '1px',
                                    }}
                                    onClick={() => this.handleDrawer()}>
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>

                        <div
                          className='accordion'
                          id='filterAccordion'
                          role='tablist'
                          aria-multiselectable='true'>
                          <div className='card'>
                            <div
                              className='card-header-list'
                              role='tab'
                              id='headingOne6'>
                              <a
                                data-toggle='collapse'
                                href='#collapseOne6'
                                aria-expanded='true'
                                aria-controls='collapseOne6'>
                                <span className='mb-0'>
                                  Business Model
                                  {`${searchQuery.businessModel?.length > 0
                                    ? ' (' +
                                    searchQuery.businessModel?.length +
                                    ')'
                                    : ''
                                    }`}{' '}
                                </span>
                                <i className='la la-angle-down'></i>
                              </a>
                            </div>

                            <div
                              id='collapseOne6'
                              className='collapse show'
                              role='tabpanel'
                              aria-labelledby='headingOne6'>
                              <OptionSelection
                                options={businessModelOption || []}
                                name='businessModel'
                                activeOptions={
                                  searchQuery.businessModel
                                    ? searchQuery.businessModel
                                    : []
                                }
                                onChange={this.handleSelectionChange}
                              />
                            </div>
                          </div>
                       {/*  <div className='card'>
                            <div
                              className='card-header-list'
                              role='tab'
                              id='headingOne8'>
                              <a
                                data-toggle='collapse'
                                href='#collapseOne8'
                                aria-expanded='true'
                                aria-controls='collapseOne8'>
                                <span className='mb-0'>
                                  {t('Emirates')}{' '}
                                  {`${searchQuery.emirates?.length > 0
                                    ? ' (' +
                                    searchQuery.emirates?.length +
                                    ')'
                                    : ''
                                    }`}
                                </span>
                                <i className='la la-angle-down'></i>
                              </a>
                            </div>
                            <div
                              id='collapseOne8'
                              className='collapse show'
                              role='tabpanel'
                              aria-labelledby='headingOne3'>
                              <OptionSelection
                                options={emirateListsOptions || []}
                                name='emirates'
                                activeOptions={
                                  searchQuery.emirates
                                    ? searchQuery.emirates
                                    : []
                                }
                                onChange={this.handleSelectionChange}
                              />
                            </div>
                          </div> */}
                          {this.state.searchQuery?.type == null ||
                            (this.state.searchQuery?.type === 'batteries' && (
                              <div className='card'>
                                <div
                                  className='card-header-list'
                                  role='tab'
                                  id='headingOne6'>
                                  <a
                                    data-toggle='collapse'
                                    href='#collapseOne6'
                                    aria-expanded='true'
                                    aria-controls='collapseOne6'>
                                    <span className='mb-0'>
                                      Category
                                      {`${searchQuery.category?.length > 0
                                        ? ' (' +
                                        searchQuery.category?.length +
                                        ')'
                                        : ''
                                        }`}
                                    </span>
                                    <i className='la la-angle-down'></i>
                                  </a>
                                </div>
                                <div
                                  id='collapseOne6'
                                  className='collapse show'
                                  role='tabpanel'
                                  aria-labelledby='headingOne6'>
                                  <OptionSelection
                                    options={categoryListsOptions}
                                    name='categoryLists'
                                    activeOptions={
                                      searchQuery.categoryLists
                                        ? searchQuery.categoryLists
                                        : []
                                    }
                                    onChange={this.handleSelectionChange}
                                  />
                                </div>
                              </div>
                            ))}
                          {this.state.searchQuery?.type === 'batteries' && (
                            <div className='card'>
                              <div
                                className='card-header-list'
                                role='tab'
                                id='headingOne6'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne6fsfesf'
                                  aria-expanded='true'
                                  aria-controls='collapseOne6fsfesf'>
                                  <span className='mb-0'>
                                    Sub Category
                                    {`${searchQuery.subCategory?.length > 0
                                      ? ' (' +
                                      searchQuery.subCategory?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-down'></i>
                                </a>
                              </div>
                              <div
                                id='collapseOne6fsfesf'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne6'>
                                <OptionSelection
                                  options={subCategoryListOptions}
                                  name='subCategoryLists'
                                  activeOptions={
                                    searchQuery.subCategoryLists
                                      ? searchQuery.subCategoryLists
                                      : []
                                  }
                                  onChange={this.handleSelectionChange}
                                />
                              </div>
                            </div>
                          )}

                          {this.state.searchQuery?.type == 'tires' && (
                            <>
                              <div className='card'>
                                <div
                                  className='card-header-list'
                                  role='tab'
                                  id='headingOne6'>
                                  <a
                                    data-toggle='collapse'
                                    href='#collapseOne7'
                                    aria-expanded='true'
                                    aria-controls='collapseOne7'>
                                    <span className='mb-0'>
                                      Category
                                      {`${searchQuery.tireCats?.length > 0
                                        ? ' (' +
                                        searchQuery.tireCats?.length +
                                        ')'
                                        : ''
                                        }`}
                                    </span>
                                    <i className='la la-angle-down'></i>
                                  </a>
                                </div>
                                <div
                                  id='collapseOne7'
                                  className='collapse show'
                                  role='tabpanel'
                                  aria-labelledby='headingOne6'>
                                  <OptionSelection
                                    options={categoryListsOptions}
                                    name='categoryLists'
                                    activeOptions={
                                      searchQuery.categoryLists
                                        ? searchQuery.categoryLists
                                        : []
                                    }
                                    onChange={this.handleSelectionChange}
                                  />
                                </div>
                              </div>

                              <div className='card'>
                                <div
                                  className='card-header-list'
                                  role='tab'
                                  id='headingOne6'>
                                  <a
                                    data-toggle='collapse'
                                    href='#collapseOne11'
                                    aria-expanded='true'
                                    aria-controls='collapseOne11'>
                                    <span className='mb-0'>
                                      Sub Category
                                      {`${searchQuery.tireSubCats?.length > 0
                                        ? ' (' +
                                        searchQuery.tireSubCats?.length +
                                        ')'
                                        : ''
                                        }`}
                                    </span>
                                    <i className='la la-angle-down'></i>
                                  </a>
                                </div>
                                <div
                                  id='collapseOne11'
                                  className='collapse show'
                                  role='tabpanel'
                                  aria-labelledby='headingOne6'>
                                  <OptionSelection
                                    options={subCategoryListOptions}
                                    name='subCategoryLists'
                                    activeOptions={
                                      searchQuery.subCategoryLists
                                        ? searchQuery.subCategoryLists
                                        : []
                                    }
                                    onChange={this.handleSelectionChange}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <div className='card'>
                            <div
                              className='card-header-list'
                              role='tab'
                              id='headingOne1'>
                              <a
                                data-toggle='collapse'
                                data-parent='#filterAccordion'
                                href='#collapseOne1'
                                aria-expanded='true'
                                aria-controls='collapseOne1'>
                                <span className='mb-0'>{t('price')}</span>
                                <i className='la la-angle-down'></i>
                              </a>
                            </div>
                            <div
                              id='collapseOne1'
                              className='collapse show'
                              role='tabpanel'
                              aria-labelledby='headingOne1'
                              data-parent='#filterAccordion'>
                              <div className='card-body'>
                                <div className='form-group'>
                                  <div className='price-range rs-primary'>
                                    <p className='d-flex justify-content-between'>
                                      <span className='amount'>
                                        {searchQuery.priceRange
                                          ? `${numberWithCommas(
                                            searchQuery.priceRange[0]
                                          )}${this.props.currency.currency
                                          } - ${numberWithCommas(
                                            searchQuery.priceRange[1]
                                          )}${this.props.currency.currency} `
                                          : `${numberWithCommas(minPrice)}${this.props.currency.currency
                                          }  - ${numberWithCommas(maxPrice)}${this.props.currency.currency
                                          } `}
                                      </span>
                                    </p>

                                    <Range
                                      min={minPrice}
                                      max={maxPrice}
                                      defaultValue={[minPrice, maxPrice]}
                                      value={
                                        searchQuery.priceRange
                                          ? searchQuery.priceRange
                                          : [minPrice, maxPrice]
                                      }
                                      onChange={(values) => {
                                        this.handleSliderChange(
                                          values,
                                          'priceRange'
                                        )
                                      }}
                                      onAfterChange={
                                        this.handleSliderAfterChange
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {filteredMake?.length > 0 && (
                            <div className='card'>
                              <div
                                className='card-header'
                                role='tab'
                                id='headingOne3'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne3'
                                  aria-expanded='true'
                                  aria-controls='collapseOne3'
                                // className="collapsed"
                                >
                                  <span className='mb-0'>
                                    {t('make')}
                                    {`${searchQuery.make?.length > 0
                                      ? ' (' +
                                      unique(searchQuery.make)?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-up'></i>
                                </a>
                              </div>
                              <div
                                id='collapseOne3'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne3'>
                                <OptionSelection
                                  options={filteredMake}
                                  page={this.state.pagemake}
                                  setpage={this.setpagemake}
                                  totalpage={totalpagemake}
                                  show={true}
                                  name='make'
                                  activeOptions={
                                    searchQuery.make ? searchQuery.make : []
                                  }
                                  onChange={this.handleSelectionChange}
                                // chooseOption={searchQuery}
                                />
                              </div>
                            </div>
                          )}

                          {filteredModel?.length > 0 && (
                            <div className='card'>
                              <div
                                className='card-header'
                                role='tab'
                                id='headingOne4'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne4'
                                  aria-expanded='true'
                                  aria-controls='collapseOne4'>
                                  <span className='mb-0'>
                                    {'Model'}
                                    {`${searchQuery.model?.length > 0
                                      ? ' (' +
                                      unique(searchQuery.model)?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-up'></i>
                                </a>
                              </div>
                              <div
                                id='collapseOne4'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne4'>
                                <OptionSelection
                                  options={filteredModel}
                                  page={this.state.pagemodel}
                                  setpage={this.setpagemodel}
                                  totalpage={totalpagemodel}
                                  show={true}
                                  name='model'
                                  activeOptions={
                                    searchQuery.model ? searchQuery.model : []
                                  }
                                  onChange={this.handleSelectionChange}
                                />
                              </div>
                            </div>
                          )}

                          {filteredBrand?.length > 0 && (
                            <div className='card'>
                              <div
                                className='card-header-list'
                                role='tab'
                                id='headingOne5'>
                                <a
                                  data-toggle='collapse'
                                  href='#collapseOne5'
                                  aria-expanded='true'
                                  aria-controls='collapseOne5'>
                                  <span className='mb-0'>
                                    Part Brands
                                    {`${searchQuery.brands?.length > 0
                                      ? ' (' +
                                      unique(searchQuery.brands)?.length +
                                      ')'
                                      : ''
                                      }`}
                                  </span>
                                  <i className='la la-angle-down'></i>
                                </a>
                              </div>

                              <div
                                id='collapseOne5'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne5'>
                                <OptionSelection
                                  options={filteredBrand}
                                  page={this.state.pagebrand}
                                  setpage={this.setpagebrnd}
                                  totalpage={totalpagebrand}
                                  show={true}
                                  name='brands'
                                  activeOptions={
                                    searchQuery?.brands
                                      ? searchQuery?.brands
                                      : []
                                  }
                                  onChange={this.handleSelectionChange}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </Drawer>
              </Box>
            </Box>
          </div>
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
    currency: state.currency,
    allCategories: state.category.allCategories,
    states: state.data.states,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    setListings: (data) => dispatch(SetListings(data)),
    initializeListing: (data, data1) => dispatch(Initialize(data, data1)),
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(withRouter(Listing))
