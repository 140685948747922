import React, { useEffect } from 'react'
import { OptionSelection } from '../content/element/filter/selection'

const MakeListData = ({ searchQuery, filteredMake, handleSelectionChange, pagemake,
    setpagemake, totalpagemake, makeListsOptions, modelList, arrModelListChange }) => {
    const getActiveMakeList = (options) => {

        if (options.length === 1) {
            const makeIdValue = makeListsOptions.filter(item => item.name === options[0].name);
            options[0]['id_car_make'] = makeIdValue[0]['id_car_make'];

            const arrModelList = modelList.filter(res => options[0]['id_car_make'] === res.id_car_make);
            if (arrModelList.length) {
                arrModelListChange(arrModelList);
            }

        }

        return options;
    }

    useEffect(() => {
        if(searchQuery.make && searchQuery.make.length) {
            getActiveMakeList(searchQuery.make)
        }
    }, [searchQuery.make])

    return (
        <div
            id='collapseOne3'
            className='collapse show'
            role='tabpanel'
            aria-labelledby='headingOne3'>
            <OptionSelection

                options={searchQuery.isVINSearch ? searchQuery?.make : filteredMake}
                page={pagemake}

                setpage={setpagemake}
                totalpage={totalpagemake}
                show={true}
                name='make'
                activeOptions={
                    searchQuery?.make ? searchQuery.make : []
                }
                onChange={handleSelectionChange}
            />
        </div>
    )
}

export default MakeListData