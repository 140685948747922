import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SearchImage from "./modal/search-image";
import VinHistory from "./modal/VinHistory";
import { getUserVinSearchHistory } from "../../../Store/action/listingActions";
import { useDispatch } from "react-redux";
import { ga4Events, getCurrentTime, popUp } from "../../../utils";
import useDebounce from "../../../hook/useDebounce";
import axios from 'axios'
import VINPartName from "./modal/VINPartName";

const Search = ({ t, showMobileSearch, handleBackFromSearch, loginToggle, searchData }) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);
//  const [openVINPart, setOpenVINPart] = React.useState(false);
  const [errorM, setErrorM] = React.useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [vinSearchData, setVinSearchData] = useState({
    categoriesVIN: [],
    catalogId: null,
    criteria: null,
    carIdentified: false,
    makeFromVIN: null,
    modelFromVIN: null,
    carId: null,
    startTime: Date.now(),
    vinNumber: null
  })
  const history = useHistory();

  const login = localStorage.getItem("login");
  const user = JSON.parse(login);

  const vinSearchRef = useRef();
  const dispatch = useDispatch();

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        vinSearchRef.current &&
        !vinSearchRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [vinSearchRef]);

  
  useEffect(() => {
    setSearch(searchData);
  }, [searchData])
  
  // use useEffect to blur the input element on component update
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && document.activeElement === vinSearchRef.current) {
        vinSearchRef.current.blur();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(getUserVinSearchHistory(debouncedValue));
    } else {
      setIsFirstRender(false);
    }
  }, [debouncedValue]);

  function handleChange(e) {
    e.preventDefault();
    setErrorM('');
    setSearch(e.target.value);
 //   setOpenVINPart(false);
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (search.length != 17) {
      setErrorM('VIN is Invalid - VIN number should be 17 Digits')
      return;
    }
    if (showMobileSearch) {
      handleBackFromSearch();
    }
    if (search) {
      ga4Events({
        action: "parts_searched_from_VIN",
        category: "Listing",
        vin_number: search,
        currentTime: getCurrentTime(),
        platform: "web",
        user_id: user?._id,
      });
    }
    setOpen(false);
   // setOpenVINPart(true);
    // history.push(`/parts-search-vin?vin=${search}`);
    getVehiclesByVIN(search); // Addted for flow change for VIn
  }
  /**
   * 
   * Added this method to call vehicles-by-vin-tradesoft and update state
   */
  const getVehiclesByVIN = async (vinCode) => {
    if (vinCode) {
      await axios
        .get(
          `/api/info/get-vehicles-by-vin-tradesoft-new?vinCode=${vinCode}&userId=${user?._id}`
        )
        .then((res) => {

          if (res.data) {
            console.log('vechical--' + res.data);


            let updatedValue = {};
            updatedValue = {
              // makeFromVIN: res.data.make,
              // modelFromVIN: res.data.model,
              // categoriesVIN: res.data.group2,
              // criteria: res.data.criteria,
              // carIdentified: true,
              // isSearchByVin: true,
              // carId: res.data.carId,
              // catalogId: res.data.catalogId
              vinNumber: res.data.vin_number
            }
            setVinSearchData((prevState) => ({ ...prevState, ...updatedValue }))
            showSparePartsTradeSoft(res.data.skus, true, vinCode, res.data.make, res.data.model, res.data.title);
          } else {
            let updatedNoValue = {};
            updatedNoValue = { carIdentified: false, isSearchByVin: true };
            setVinSearchData((prevState) => ({ ...prevState, ...updatedNoValue }))
          }
        })
        .catch((err) => {
          let updatedErrorValue = {};
          updatedErrorValue = {
            carIdentified: false,
            isSearchByVin: false,
          }
          setVinSearchData((prevState) => ({ ...prevState, ...updatedErrorValue }))

          if (err?.response?.status === 404) {
            popUp('Cannot find the result for given VIN number.')
            return
          }
          popUp('There was a problem with server. Please try again.')
        })
    }
  }

  // const formatNumber = (numberArr) => {
  //   return numberArr.map(number => {
  //     let numberStr =  number.toString();
  //     return numberStr.slice(0, 5) + '-' + numberStr.slice(5);
  //   })

  // }
  /**
 * 
 * Call from getVehiclesByVIN and redirect to PLP page
 */
  const showSparePartsTradeSoft = (skus, vinSearch, vin, make, model, title) => {
    // skus = skus.slice(0, 30);
    // skus = formatNumber(skus);
    if (vinSearch) {
      // this.setState({ isLoadingSpareParts: true })
      const extraData = {
        vinSearch: vinSearch,
        makeText: make,
        modelText: model,
        modeltitleText: title,
        // startTime: updatedValue.startTime,
        // endTime: Date.now(),
        skus: [...new Set(skus)],
      }
      history.push({
        pathname: `/all-listings`,
        search: `?type=vin-search&vin=${vin}`,
        state: { type: 'vin-search', extraData },
      })
    }
  }

  const uploadFile = (e) => {
    setShowModal(true);
  };
  const onClose = () => {
    setShowModal(false);
  };

  const handleVinSearchClick = (event) => {
    event.preventDefault();
    dispatch(getUserVinSearchHistory(search));
    setOpen((prev) => !prev);
  };

  return (
    <Fragment>
      <SearchImage showModal={showModal} onClose={onClose} />
      <div className="directory_content_area">
        <div className="col-md-12" style={{ padding: 0 }} ref={vinSearchRef}>
          {showMobileSearch
            ?
            <form className="search_img display-flex">
              <div className="search_form no-padding col-md-12" >
                <div className="atbd_seach_button_wrapper">
                  <button
                    type="button"
                    className="btn btn-md btn-primary btn_search d-flex pl-2"
                  >
                    <i className="las la-search" />
                  </button>
                </div>

                <input
                  type="text"
                  style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
                  value={search || ""}
                  name="vin"
                  onChange={handleChange}
                  placeholder={t("Enter 17 Digit VIN")}
                  autoComplete="off"
                  onFocus={handleVinSearchClick}
                  onBlur={() => setOpen(true)}
                  onKeyDown={(event) => event.key == 'Enter' && handleSubmit(event)}
                />

              </div>
            </form>
            :
            <form onSubmit={handleSubmit} className="search_img display-flex">
              <div className="search_form no-padding search-width col-md-10" >
                <div className="atbd_seach_button_wrapper">
                  <button
                    type="button"
                    className="btn btn-md btn-primary btn_search d-flex pl-2"
                  >
                    <i className="las la-search" />
                  </button>
                </div>

                <input
                  type="text"
                  style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
                  value={search || ""}
                  name="vin"
                  onChange={handleChange}
                  placeholder={t("Enter 17 Digit VIN")}
                  autoComplete="off"
                  onFocus={handleVinSearchClick}
                  onBlur={() => setOpen(true)}
                />
                <div className="atbd_seach_button_wrapper_start" x>
                  <button
                    type="button"
                    onClick={uploadFile}
                    className="btn btn-md btn-primary btn_search d-flex p-1" style={{ marginRight: '13px' }}
                  >
                    {<i className="las la-camera" />}
                  </button>
                </div>
              </div>
              <div className="col-md-2" style={{ paddingRight: '3px' }}>
                {
                  !currentUser
                    ?
                    <button
                      type="button"
                      className="btn btn-primary search-btn"
                      onClick={loginToggle}
                    >
                      SEARCH
                    </button>
                    :
                    <button
                      type="submit"
                      className="btn btn-primary search-btn"

                    >
                      SEARCH
                    </button>
                }
              </div>
            </form>
          }
          {errorM && <div className="text-danger" style={{ position: 'absolute' }}>{errorM}</div>}



         {open && user && Object.keys(user).length !== 0 && (
            <VinHistory setOpen={setOpen} setSearch={setSearch} />

          )} 

        </div>
      </div>
    </Fragment>
  );
};

export default withTranslation()(Search);
